import {useMemo} from 'react';

import BasePageLayout from '@/components/shared/Page/PageLayout';
import PartnerDirectoryHeaderComponent from '@/pages/shopify.com/($locale)/partners/directory/components/Header/Header';
import secondaryNavFn from '@/pages/shopify.com/($locale)/partners/directory/components/Header/secondaryNavFn';
import BaseFooter from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum} from '@/enums';
import type {PartnersDirectoryUser} from '@/pages/shopify.com/($locale)/partners/directory/types';

interface Props {
  title?: string;
  metaDescription?: string;
  user?: PartnersDirectoryUser;
  secondaryNavPageTitle: string;
  hasScrollRestoration?: boolean;
  children: React.ReactNode;
}

export default function PartnersDirectoryPageLayout({
  title,
  metaDescription,
  user,
  secondaryNavPageTitle,
  hasScrollRestoration: shouldRestoreScroll = false,
  children,
}: Props) {
  const Header = useMemo(
    () =>
      function PartnerDirectoryHeader() {
        return (
          <PartnerDirectoryHeaderComponent
            user={user}
            secondaryNavFn={secondaryNavFn}
            secondaryNavPageTitle={secondaryNavPageTitle}
          />
        );
      },
    [user, secondaryNavPageTitle],
  );

  return (
    <BasePageLayout
      title={title}
      isPureTitle
      metaDescription={metaDescription}
      header={Header}
      footer={
        <BaseFooter
          colorScheme={ColorSchemeEnum.Dark}
          className="bg-partners-purple"
        />
      }
      hasScrollRestoration={shouldRestoreScroll}
    >
      {children}
    </BasePageLayout>
  );
}
